:root {
  --blue: #1e90ff;
  --clr-white: #ffffff;
  --white-opacity-50: hsla(0, 0%, 100%, 0.5);
  --base-color: #060639;
  --d: 5000ms;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: #060639;
  --c2: rgba(168, 239, 255, 0.1);
}

body,
html {
  font-family: "Poppins", sans-serif;
  font-size: 25px;
}
#header-top-nav-bar {
  /* position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99; */
  background-color: white;
  /* background: transparent !important; */
}
.intro-section {
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  position: relative;
  /* z-index: 10; */
}
.overlay:after {
  background: linear-gradient(
    180deg,
    rgba(3, 15, 39, 0.7) 0,
    rgba(3, 15, 39, 0)
  );
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  /* z-index: -1; */
}
#header-top-nav-bar .navbar-nav > .nav-item > .nav-link {
  color: var(--base-color);
  /* font-weight: bold !important; */
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.375;
  padding: 8px 25px;
  border-bottom: 3px solid transparent;
}
#header-top-nav-bar .navbar-nav > .nav-item > .nav-link:hover {
  color: var(--base-color);
  border-bottom: 3px solid var(--base-color);
  transition: transform 0.5s linear;
  font-weight: bold !important;
}
/* #header-top-nav-bar .navbar-brand {
  font-size: 40px;
  color: var(--base-color);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: cursive;
  background: -webkit-linear-gradient(var(--base-color), var(--base-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */
#main-nav-bar {
  box-shadow: 0px 4px 8px 2px rgba(69, 61, 219, 0.5);
}
#main-nav-bar .navbar-brand {
  font-size: 1em !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: bold !important;
  font-family: cursive !important;
  background: -webkit-linear-gradient(var(--base-color), var(--base-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: auto;
}
#main-nav-bar .nav-link {
  font-size: 20px !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: 600;
  background: -webkit-linear-gradient(var(--base-color), var(--base-color));
  border-bottom: 3px solid transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#main-nav-bar .nav-link span:hover {
  color: var(--base-color);
  border-bottom: 2px solid var(--base-color);
  transition: transform 0.5s linear;
  font-weight: bold !important;
}
#main-nav-bar .navbar-brand img {
  width: 60px;
  margin-right: 10px;
}
#header-top-nav-bar .navbar-brand img {
  width: 15%;
}
#header-top-nav-bar {
  border-bottom: 1px solid var(--white-opacity-50);
}

#header-top-nav-bar .navbar-brand:before {
  background: red;
}

.bottom-bar-container {
  background-color: #37383a;
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99;
  padding: 6px;
}
.bottom-bar-container .inner {
  font-size: 16px;
}
.bottom-bar-container .bottom-bar-inner-wrap {
  display: flex;
  justify-content: space-around;
}
.home-about-desc {
  font-size: 16px;
  text-align: justify;
}
.home-content-main-title {
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 10px;
  font-size: 20px;
}
#home-about img {
  border-radius: 15px;
}
#home-about .about-features ul > li {
  font-size: 18px;
  text-align: left;
}

.bottom-bar-socials > .inner .icons a {
  color: white;
}
.header-section.is-sticky
  #header-top-nav-bar
  .navbar-nav
  > .nav-item
  > .nav-link {
  color: black !important;
}
.header-section.is-sticky #header-top-nav-bar .navbar-brand {
  color: black !important;
}
.header-section.is-sticky #header-top-nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100px;
  background-color: white !important;
  color: var(--blue);
  width: 100%;
  z-index: 99;
  height: 80px;
  -webkit-animation: headerSlideDown 0.95s ease forwards;
  animation: headerSlideDown 0.95s ease forwards;
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
}
@-webkit-keyframes headerSlideDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes headerSlideDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.logo-style {
  /* font-size: 32px; */
  font-size: initial;
  font-weight: bold;
  color: #060639;
}

.card-text-feature {
  font-size: 15px;
  margin: 0.5rem auto;
  color: #060639;
}
#about-us-feature .card-body {
  padding: 12px 4px;
}

#about-us-feature .card {
  /* box-shadow: 20px 20px 30px -6px rgba(0, 0, 0, 0.1); */
  box-shadow: 10px 10px 10px -1px rgb(0 0 0 / 10%);
  background: linear-gradient(#ebebeb, #d4d4d536);
}
#about-us-feature .card:hover {
  scale: 105%;
  transition: transform 0.5s ease-out;
}
/* FEATURE CARD DESIGN */

@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}
.gradient-box {
  border: 0.1rem solid;
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--blue),
      0.1turn,
      var(--blue) 0.15turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;
}

@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}

.sub-title::before {
  position: absolute;
  height: 3px;
  width: 60px;
  content: "";
  transform: rotate(-180deg);
  background: linear-gradient(
    246.06deg,
    var(--blue) 15.37%,
    rgba(255, 255, 255, 0) 116.95%
  );
}

.section-break {
  border-top: 2px solid var(--base-color);
  width: 80%;
  margin: auto;
}

/* WE OFFER CARD DESIGN */
#we-offer .card {
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  transition: 0.5s;
  margin: 1rem auto;
}

#we-offer .card:nth-child(1) .box .content a {
  background: #2196f3;
}

#we-offer .card:nth-child(2) .box .content a {
  background: #e91e63;
}

#we-offer .card:nth-child(3) .box .content a {
  background: #23c186;
}

#we-offer .card .box {
  /* position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
  background-image: linear-gradient(
    to right bottom,
    #021023,
    #021328,
    #04244b,
    #04326a,
    #074fac
  ); */
  background-image: linear-gradient(
    to right bottom,
    #021023,
    #021328,
    #04244b,
    #04326a,
    #074fac
  );
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border-radius: 15px;
  margin: 0.8rem;
  height: 100%;
}

#we-offer .card .box:hover {
  transform: translateY(-50px);
}

/* #we-offer .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.03); 
  background: var(--base-color);
} */

#we-offer .card .box .content {
  padding: 20px;
  text-align: center;
}

#we-offer .card .box .content h5 {
  font-size: 1.05rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

#we-offer .card .box .content p {
  font-size: 0.6rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

#we-offer .card .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: var(--base-color);
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  font-size: 15px;
}
#we-offer .card .box .content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}
.home-content-main-para {
  font-size: 16px;
}
#footer {
  min-height: 10rem;
  background-color: var(--base-color);
  color: white;
  padding: 2% 4%;
  background-image: linear-gradient(
    to right bottom,
    #021023,
    #021328,
    #04244b,
    #04326a,
    #074fac
  );
}
#footer .nav-item .nav-link {
  color: white;
  font-size: 15px;
}
.feature-bullet-in {
  text-align: initial;
  list-style-type: disclosure-closed;
  font-size: 21px;
}
.feature-bullet-in li:hover {
  transform: translateX(5%);
  transition: transform 0.2s ease-out;
  color: #021328;
}
.feature-bullet-in li small:hover {
  color: #021328;
}
.feature-image {
  border-radius: 20px;
}
.feature-image:hover {
  transform: scale3d(1.1, 1.1, 1.1);
  transition: transform 0.2s;
}
.border-divider-right {
  border-right: 1px solid var(--base-color);
}
.border-divider-left {
  border-left: 1px solid var(--base-color);
}
#courses-section .card {
  background: whitesmoke !important;
  border-radius: 10px;
}

#courses-section .card .card-body .card-title {
  font-size: 20px;
  font-weight: bold;
  color: #37383a;
}
/* #courses-section .card .card-head {
  /* margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px; 
  border-bottom: 1px solid var(--base-color);
} */

#courses-section .card .card-head .card-title {
  font-size: 20px;
  font-weight: bold;
  /* margin-top: 10px 90% 0px 90%; */
  margin-bottom: 0px !important;
  color: #37383a;
}
#courses-section .card .card-body .card-text {
  font-size: 14px;
}

#courses-section .card .card-footer {
  background-color: var(--base-color);
  color: white;
}
#courses-section .card .card-footer p {
  font-size: 14px;
  margin: 0px;
}
#courses-section .card .card-footer .badge {
  font-size: 13px;
  font-weight: 600;
}
#courses-section .card {
  box-shadow: 30px 30px 30px -6px #adb5bddb;
  /* background: linear-gradient(#ebebeb, #d4d4d536); */
  background: white !important;
}
#courses-section .course-card-image {
  width: 90%;
  height: 180px;
  border-radius: 20px;
  object-fit: fill;
}
.course-heading {
  font-size: 28px;
  font-weight: bold;
}

#courses-section .card:hover {
  background: white !important;
  -webkit-animation: courseCard 0.95s ease forwards;
  animation: courseCard 0.55s ease forwards;
  border: 2px solid var(--base-color);
}
#courses-section .card:hover .card-title {
  color: var(--base-color);
}
#courses-section .card:hover .card-text {
  /* transform: translateY(-50px); */
  font-weight: 600;
  transition: transform 2s;
}
@-webkit-keyframes courseCard {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

@keyframes courseCard {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}
#course-container .nav .nav-item .nav-link {
  font-size: 16px;
  color: var(--base-color);
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
}
#course-container .nav {
  font-size: 16px;
  list-style: disc;
  color: var(--base-color);
}
#course-container .courses-quick-link {
  /* height: auto;
  width: 200px;
  position: sticky;
  z-index: 1;
  left: 0;
  background-color: var(--base-color);
  overflow-x: hidden;
  transition: 0.5s;
  margin-top: 0px !important;
  padding-top: 60px;
  color: white; */
  background-color: var(--base-color);
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: 0px !important;
  color: white;
}
#course-container .courses-quick-link .courses-quick-link-inner {
  position: sticky;
  top: 30%;
  z-index: 1020;
}
#course-container .courses-quick-link li.nav-item:hover {
  border-right: 4px solid white;
  background: white;
  border-radius: 10px;
}
.courses-quick-link ul li a:hover {
  /* transform: scale(1.05); */
  color: var(--base-color) !important;
  font-weight: bold;
}
#course-container .card-footer i {
  color: white;
  font-weight: 600;
}
.course-sidebar-heading {
  font-size: 17px;
  font-weight: bold;
}
body {
  overflow-x: hidden;
}
.enquiry-btn {
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 5px 35px !important;
  /* background: linear-gradient(90deg, #0072ff 0%, #00d4ff 100%) !important; */
  transition: all 0.3s ease;
  animation: kreep 1s ease 2s infinite alternate;
  background: rgb(14, 8, 123);
  background: linear-gradient(
    90deg,
    rgb(14, 8, 123) 17%,
    rgb(39, 90, 179) 46%,
    rgb(131, 226, 252) 100%
  );
}

.enquiry-btn:hover {
  transform: scale(1.05);
}

#enquiry-modal,
#enquiry-modal .form-control {
  font-size: 0.6rem;
}

.font-size-7 {
  font-size: 0.7rem !important;
}
.font-size-6 {
  font-size: 0.6rem !important;
}
.font-size-5 {
  font-size: 0.5rem !important;
}
#enquiry-modal .form-control:focus {
  /* color: #212529;
  background-color: whitesmoke;
  border-color: var(--base-color);
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(3, 23, 53, 0.25); */
}
#enquiry-modal .form-control {
  padding: 0.3rem 0.75rem;
}
#enquiry-modal .form-label {
  color: var(--base-color);
}
#enquiry-modal .modal-title.h4 {
  color: var(--base-color);
}
#enquiry-modal .form-select {
  font-size: 0.6rem;
}

.Toastify__toast-body {
  font-size: 16px !important;
}

/*Bounce*/
@keyframes kreep {
  0% {
    -webkit-transform: scale(1.05, 0.95);
    transform: scale(1.05, 0.95);
  }
  50% {
    -webkit-transform: scale(0.95, 1.1) translateY(-0.2rem);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.download-syllabus {
  cursor: pointer;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: linear-gradient(
    to top,
    #c4c5c7 0%,
    #dcdddf 52%,
    #ebebeb 100%
  );
}
.contact-map {
  border: 2px solid gray;
  margin: auto;
  border-radius: 10px;
  padding: 15px;
}
.contact-us-icon a {
  color: var(--base-color);
}
.job-details {
  text-align: left;
}
.job-desc-text {
  font-size: 17px;
  margin: auto;
}
.place-center {
  display: grid;
  place-items: center;
}
.job-exp-text,
.job-role-text {
  font-size: 16px;
  margin-bottom: 0px;
}

.we-offer-view-button {
  font-size: 16px !important;
  padding-left: 15% !important;
  padding-right: 15% !important;
}
.read-more {
  text-decoration: underline;
  color: #04326a;
}
.details-btn {
  position: absolute;
  right: 30px;
  font-size: 16px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.details-btn-mobile {
  font-size: 16px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.card-shadow {
  /* box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.75); */
  box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.75);
  border: 1px solid gray !important;
}

.btn-close:focus {
  box-shadow: none !important;
}

* {
  scroll-margin-top: 85px;
}
.navbar-toggler {
  box-shadow: 0px 1px 1px 2px rgba(69, 61, 219, 0.5) !important;
  padding: 2px 10px !important;
}
.navbar-toggler:focus {
  box-shadow: 0px 1px 1px 4px rgba(69, 61, 219, 0.5) !important;
}
img.home-abt-image:hover {
  transform: scale3d(1.05, 1.05, 1.05);
  transition: transform 0.2s;
}
